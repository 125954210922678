import { graphql } from "gatsby";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import {
  SectionContainer,
  SectionWrapper
} from "../common/Section";

import { ErrorHero } from "../components/Hero";


interface Props {
  data: any
}


const NotFoundPage = ({
  data
}: Props ) => {
  const socials = data?.allPrismicSocial?.edges || [];

  const global = data?.prismicSiteInfo?.data || {};

  return (
    <Layout
      global={ global }
      socials={ socials }
    >
      <Head
        title="404: Not Found"
      />

      <SectionWrapper>
        <SectionContainer>
          <ErrorHero
            heading="404: Page Not Found"
            description="The page that you have requested doesn't exist."
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query Error404PageQuery {
    allPrismicSocial {
      edges {
        node {
          _previewable
          data {
            platform
            link {
              url
            }
          }
          uid
        }
      }
    }

    prismicSiteInfo {
      _previewable
      data {
        phone_number {
          text
        }
        phone_number_link {
          url
        }

        email {
          text
        }
        email_link {
          url
        }

        address {
          text
        }

        accreditations {
          image {
            alt
            gatsbyImageData(
              height: 40
              placeholder: BLURRED
            )
          }
        }

        copyright {
          text
        }
      }
      uid
    }
  }
`;


export default NotFoundPage;
